import React from 'react';
import './App.css';
import './index.css';
import Header from './components/Header';
import AboutUs from './components/AboutUs';
import Home from './components/Home';
import CompanyMembers from './components/CompanyMembers';
import AboutCompany from "./components/AboutCompany";
import WhatWeOffer from "./components/WhatWeOffer";
import Prices from "./components/Prices";
import FallingStars from './components/FallingStars';
import Footer from './components/Footer';

function App() {
    return (
        <div className="min-h-screen bg-gray-900 text-white relative">
            <FallingStars /> {/* Falling stars effect in the background */}
            <div className="relative z-10"> {/* Ensure the main content is above the falling stars */}
                <Header />
                <Home />
                <AboutUs />
                <AboutCompany />
                <WhatWeOffer />
                <Prices />
                <CompanyMembers />
                <Footer />
            </div>
        </div>
    );
}

export default App;