import { useEffect } from 'react';

const FallingStars = () => {
    useEffect(() => {
        const createStar = () => {
            const star = document.createElement('div');
            star.className = 'star';

            // Random position for each star
            star.style.left = `${Math.random() * window.innerWidth}px`;

            // Random size for variety in stars
            const starSize = Math.random() * 3 + 1; // Between 1px and 4px
            star.style.width = `${starSize}px`;
            star.style.height = `${starSize}px`;

            // Random animation duration for different falling speeds
            star.style.animationDuration = `${Math.random() * 6 + 8}s`; // Between 2s and 3.5s

            // Append star to the body
            document.body.appendChild(star);

            // Remove the star after its fall
            setTimeout(() => {
                star.remove();
            }, 10000); // Remove star after 4 seconds
        };

        // Create stars more frequently (every 100ms)
        const intervalId = setInterval(createStar, 100);

        return () => clearInterval(intervalId); // Clean up on unmount
    }, []);

    return null;
};

export default FallingStars;
