import React from 'react';

const Prices = () => {

    return (
        <div id="Prices" className="min-h-screen flex flex-col items-center justify-center p-8 bg-gray-900">
            {/* Image Container */}
            <div className="text-4xl text-white font-bold mb-5 text-center">
                <h1>
                    <span className="text-blueCustom">Cenas</span>
                </h1>
            </div>
            <div className="w-40 h-1 bg-white"></div>
            <div className="grid grid-cols-1 lg:grid-cols-3 mt-20 gap-10">
                {/* Easy Plan */}
                <div className="flex flex-col items-center text-center bg-gray-800 p-8 rounded-lg shadow-lg border border-blueCustom">
                    <h3 className="text-2xl font-bold text-blueCustom mb-4">Easy</h3>
                    <p className="text-lg font-bold text-white">Sākuma līmenī tiek iekļauts:</p>
                    <ul className="text-white text-left mt-4">
                        <li className="py-2">Vienkāršs dizains</li>
                        <li className="py-2">Vienas lapas vietne, ar tādām daļām kā, "Par", "Pakalpojumi", "Kontakti" utt.</li>
                        <li className="py-2">Responsīvs saites dizains (Telefoniem draudzīgs)</li>
                        <li className="py-2">Link uz Jūsu sociālajiem tīkliem</li>
                    </ul>
                    <div className="text-4xl text-blueCustom font-bold mt-auto">
                        <h1>300-500 Euro</h1>
                    </div>
                </div>

                {/* Average Plan */}
                <div className="flex flex-col items-center text-center bg-gray-800 p-8 rounded-lg shadow-lg border border-blueCustom">
                    <h3 className="text-2xl font-bold text-blueCustom mb-4">Average</h3>
                    <p className="text-lg font-bold text-white">Vidējā līmenī tiek iekļauts:</p>
                    <ul className="text-white text-left mt-4">
                        <li className="py-2">Radošāks mājaslapas izkārtojums, kas saskan ar jūsu logo</li>
                        <li className="py-2">Mājaslapa ar vairākām lapām, (Home, About, Services, Blog, Contact, utt.)</li>
                        <li className="py-2">Kustīgi elementi, piemēram, bildes, kas pašas mainās</li>
                        <li className="py-2">Kontaktu forma ar e-mail</li>
                        <li className="py-2">Responsīvs saites dizains, (Telefoniem draudzīgs)</li>
                        <li className="py-2">Link uz Jūsu sociālajiem tīkliem</li>
                    </ul>
                    <div className="text-4xl text-blueCustom font-bold mt-auto">
                        <h1>500-900 Euro</h1>
                    </div>
                </div>

                {/* Complex Plan */}
                <div className="flex flex-col items-center text-center bg-gray-800 p-8 rounded-lg shadow-lg border border-blueCustom">
                    <h3 className="text-2xl font-bold text-blueCustom mb-4">Complex</h3>
                    <p className="text-lg font-bold text-white">Augstākajā līmenī tiek iekļauts:</p>
                    <ul className="text-white text-left mt-4">
                        <li className="py-2">Radošāks mājaslapas izkārtojums, kas saskan ar jūsu logo, krāsas un elementu izvietojums pēc jūsu pieprasījuma</li>
                        <li className="py-2">Mājaslapa ar vairākām lapām, (Home, About, Services, Blog, Contact, utt.)</li>
                        <li className="py-2">Kustīgi elementi, bildes, kas mainās, teksts, kas kustās lai piesaistītu cilvēku uzmanību un tam līdzīgi.</li>
                        <li className="py-2">Iespējams mājaslapai mainīt valodu no latviešu uz angļu</li>
                        <li className="py-2">Kontaktu forma ar e-mail</li>
                        <li className="py-2">Responsīvs saites dizains, (Telefoniem draudzīgs)</li>
                        <li className="py-2">Link uz Jūsu sociālajiem tīkliem</li>
                    </ul>
                    <div className="text-4xl text-blueCustom font-bold mt-auto">
                        <h1>900-1500 Euro</h1>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Prices;