import React from 'react';
import {FaChartLine, FaCheckCircle, FaCog, FaUsers} from "react-icons/fa";
import {WiStars} from "react-icons/wi";

const AboutUs = () => {

    return (
        <div id="AboutUs" className="min-h-screen flex flex-col items-center justify-center p-4 sm:p-8">
            {/* Image Container */}
            <div className="text-2xl sm:text-4xl text-white font-bold mb-5 text-center">
                <h1>
                    Iepazīsti <span className="text-blueCustom">Mūsu Vērtības!</span>
                </h1>
            </div>
            <div className="w-20 sm:w-40 h-1 bg-white"></div>

            {/* Values Section */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-12 mt-16 sm:mt-28">
                <div className="flex flex-col items-center text-center gap-5">
                    <FaCog className="text-4xl sm:text-6xl"/>
                    <h3 className="text-lg sm:text-xl font-bold">Inovācijas</h3>
                    <p className="text-sm sm:px-10 lg:px-20">LucidWeb ir Jūsu partneris inovatīvu tīmekļa vietņu
                        veidošanā. Mēs lepojamies ar to, ka esam unikāli un inovatīvi.</p>
                </div>
                <div className="flex flex-col items-center text-center gap-5">
                    <FaChartLine className="text-4xl sm:text-6xl"/>
                    <h3 className="text-lg sm:text-xl font-bold">Izaugsme</h3>
                    <p className="text-sm sm:px-8 lg:px-12">Mēs LucidWeb ieviešam jaunu skatījumu uz tīmekļa vietnēm. Ar
                        katru izveidoto tīmekļa vietni mēs kopā augam.</p>
                </div>
                <div className="flex flex-col items-center text-center gap-5">
                    <FaUsers className="text-4xl sm:text-6xl"/>
                    <h3 className="text-lg sm:text-xl font-bold">Sabiedrība</h3>
                    <p className="text-sm sm:px-6 lg:px-8">Maksimāli cenšamies atspoguļot Jūsu redzējumu realitātē. Ar
                        Mums sadarbojoties Jūs saņemat vairāk nekā tīmekļa vietni,
                        Jūs saņemat partneri, kas ir apņēmies nodrošināt Jūsu panākumus.</p>
                </div>
                <div className="flex flex-col items-center text-center gap-5">
                    <FaCheckCircle className="text-4xl sm:text-6xl"/>
                    <h3 className="text-lg sm:text-xl font-bold">Mērķi</h3>
                    <p className="text-sm sm:px-8 lg:px-12">Ar LucidWeb nevar kļūdīties, var tikai izaugt un realizēt
                        savus sapņus par pasakainu tīmekļa vietni!</p>
                </div>
            </div>

            {/* Closing Statement */}
            <div
                className="mt-20 sm:mt-52 animate-pulse flex flex-col sm:flex-row text-center items-center justify-center">
                <h4 className="text-3xl sm:text-5xl font-semibold">
                    Katrs Pikselis <span className="text-blueCustom">atspoguļo topošos sapņus</span>
                </h4>
                <WiStars className="text-6xl sm:text-8xl mt-4 sm:mt-0 sm:ml-4"/>
            </div>
        </div>
    );
}

export default AboutUs;