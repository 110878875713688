import React from "react";
import { Link } from 'react-scroll'; // Import Link from react-scroll
import logo from "../img/lucidweb_logo.png";
import { FaChartLine, FaCheckCircle, FaCog, FaUsers } from "react-icons/fa";

const IconWithTooltip = ({ icon, description }) => (
    <div className="relative group flex items-center justify-center">
        <div className="w-16 h-16 bg-gradient-to-br from-fuchsia-500 via-gray-450 to-emerald-600 rounded-full flex items-center justify-center transform transition-transform duration-300 group-hover:scale-110">
            {icon}
        </div>
        <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-full mt-2 px-4 py-2 bg-gray-800 text-white text-sm font-semibold rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300">
            {description}
        </div>
    </div>
);

const Home = () => {
    return (
        <div className="flex flex-col items-center mt-16 pt-20 relative z-10">
            <div className="w-[28rem] h-[30rem] rounded-full flex items-center justify-center">
                <img src={logo} alt="LucidWeb Logo" className="w-full h-full object-cover rounded-full" />
            </div>
            <div className="text-3xl text-white mb-8 font-bold">
                Lucid<span className="text-blueCustom">Web</span>
            </div>

            <div className="flex flex-col items-center mb-16 relative z-10">
                <div className="flex items-center justify-center mb-8">
                    <Link
                        to="AboutUs"
                        smooth={true}
                        duration={800}
                        className="px-4 md:px-8 lg:px-12 py-3 bg-blue-400 text-white rounded-l-3xl font-bold cursor-pointer"
                    >
                        Par Mums
                    </Link>
                    <Link
                        to="AboutCompany"
                        smooth={true}
                        duration={800}
                        className="px-4 md:px-8 lg:px-12 py-3 bg-emerald-500 text-white font-bold cursor-pointer"
                    >
                        Par Kompāniju
                    </Link>
                    <Link
                        to="WhatWeOffer"
                        smooth={true}
                        duration={800}
                        className="px-4 md:px-8 lg:px-12 py-3 bg-indigo-500 text-white rounded-r-3xl font-bold cursor-pointer"
                    >
                        Pakalpojumi
                    </Link>
                </div>

                <div className="flex space-x-4">
                    <IconWithTooltip
                        icon={<FaCog className="text-white text-2xl" />}
                        description="Inovācija"
                    />
                    <IconWithTooltip
                        icon={<FaChartLine className="text-white text-2xl" />}
                        description="Izaugsme"
                    />
                    <IconWithTooltip
                        icon={<FaUsers className="text-white text-2xl" />}
                        description="Sabiedrība"
                    />
                    <IconWithTooltip
                        icon={<FaCheckCircle className="text-white text-2xl" />}
                        description="Mērķi"
                    />
                </div>

            </div>
        </div>
    );
};

export default Home;
