import React, { useState } from "react";
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';

const Footer = () => {
    const [showContactForm, setShowContactForm] = useState(false);
    const [contactFormAnimation, setContactFormAnimation] = useState('');

    const toggleContactForm = () => {
        if (!showContactForm) {
            setShowContactForm(true);
            setContactFormAnimation('contact-form-enter');
            setTimeout(() => setContactFormAnimation('contact-form-enter-active'), 10);
        } else {
            setContactFormAnimation('contact-form-exit');
            setTimeout(() => {
                setShowContactForm(false);
                setContactFormAnimation('');
            }, 700);
        }
    };

    return (
        <>
            <footer className="bg-gray-900 text-gray-100 py-12 px-8 relative before:absolute before:inset-x-0 before:top-[-20px] before:h-8 before:bg-gradient-to-b before:from-gray-900 before:to-transparent before:shadow-md before:z-10">
                <div className="container mx-auto grid lg:grid-cols-3 gap-8 text-center lg:text-left">

                    {/* First Section */}
                    <div className="lg:col-span-1">
                        <h2 className="text-3xl font-bold mb-4">Sazinies ar mums</h2>
                        <p className="mb-6 text-gray-400">
                            Mēs specializējamies vietņu dizaina uzlabošanā un to izveidē no nulles,
                            nodrošinot mūsdienīgus, lietotājam draudzīgus risinājumus, kas pielāgoti jūsu zīmola vajadzībām.
                        </p>

                        {/* Social Media Icons */}
                        <div className="flex justify-center lg:justify-start space-x-4 mt-6">
                            <a href="#" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-gray-100">
                                <FaFacebook size={30} />
                            </a>
                            <a href="https://www.instagram.com/lucidiousweb/" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-gray-100">
                                <FaInstagram size={30} />
                            </a>
                            <a href="https://x.com/LucidiousWeb" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-gray-100">
                                <FaTwitter size={30} />
                            </a>
                        </div>
                    </div>

                    {/* Second Section */}
                    <div className="lg:col-span-2 flex flex-col items-center md:flex-row space-y-8 md:space-y-0 md:space-x-8 justify-center">
                        <div className="bg-gray-800 p-6 rounded-lg relative shadow-lg border border-indigo-500 w-full max-w-xs mx-auto md:mx-0 flex flex-col items-center">
                            <div className="text-center text-gray-400 mb-2">
                                <i className="fas fa-basketball-ball text-3xl"></i>
                            </div>
                            <button
                                onClick={toggleContactForm}
                                className="hover:text-blue-500 cursor-pointer duration-500 ease-in-out text-xl"
                            >
                                Sazināties
                            </button>
                            <div className="absolute inset-0 -right-4 -bottom-4 bg-gray-700 rounded-lg z-[-1]"></div>
                        </div>

                        <div className="bg-gray-800 p-6 rounded-lg relative shadow-lg border border-indigo-500 w-full max-w-xs mx-auto md:mx-0">
                            <div className="text-center text-gray-400 mb-2">
                                <i className="fas fa-envelope text-3xl"></i>
                            </div>
                            <p className="text-gray-100 text-center">lucidious.websites@gmail.com</p>
                            <div className="absolute inset-0 -right-4 -bottom-4 bg-gray-700 rounded-lg z-[-1]"></div>
                        </div>
                    </div>
                </div>
            </footer>

            {/* Contact Form */}
            {showContactForm && (
                <div
                    className={`fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 ${contactFormAnimation}`}
                >
                    <div
                        className="bg-gray-900 p-4 md:p-8 rounded-lg shadow-lg space-y-4 relative min-w-[24rem] mx-4"
                    >
                        <button
                            className="absolute top-4 right-4 text-white hover:text-gray-500 text-2xl"
                            onClick={toggleContactForm}
                        >
                            <IoMdClose />
                        </button>
                        <div className="text-center">
                            <div className="text-2xl md:text-3xl text-white font-bold">
                                <h1>Sazinies ar mums</h1>
                            </div>
                        </div>
                        <form
                            action="https://getform.io/f/02242e13-485a-4074-8c5a-5981f39bfc7e"
                            method="POST"
                            className="flex flex-col w-full"
                        >
                            <input
                                type="text"
                                name="email"
                                placeholder="E-pasts"
                                className="mb-2 p-2 bg-transparent border-2 border-blue-500 rounded-md text-gray-300 focus:outline-none min-w-[24rem]"
                            />
                            <input
                                type="text"
                                name="Phone Number"
                                placeholder="Telefona Numurs"
                                className="my-2 p-2 bg-transparent border-2 border-blue-500 rounded-md text-gray-300 focus:outline-none min-w-[24rem]"
                            />
                            <input
                                type="text"
                                name="name"
                                placeholder="Vārds, Uzvārds"
                                className="my-2 p-2 bg-transparent border-2 border-blue-500 rounded-md text-gray-300 focus:outline-none min-w-[24rem]"
                            />
                            <textarea
                                name="message"
                                rows="6"
                                placeholder="Ziņojums"
                                className="my-2 p-2 bg-transparent border-2 border-blue-500 rounded-md text-gray-300 focus:outline-none min-w-[24rem]"
                            />
                            <button
                                className="text-gray-200 bg-blue-500 shadow-lg shadow-blue-500/50 px-4 md:px-6 py-3 my-8 mx-auto flex items-center rounded-md hover:scale-110 duration-300"
                            >
                                Send
                            </button>
                        </form>
                    </div>
                </div>
            )}
        </>
    );
};

export default Footer;
