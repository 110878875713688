import React from 'react';

const WhatWeOffer = () => {

    return (
        <div id="WhatWeOffer" className="min-h-screen flex flex-col items-center justify-center p-4 sm:p-8">
            <div className="text-2xl sm:text-4xl text-white font-bold text-center mb-5">
                <h1>
                    Ko <span className="text-blueCustom">Mēs Piedāvājam?</span>
                </h1>
            </div>
            <div className="w-20 sm:w-40 h-1 bg-white"></div>

            {/* Responsive Grid Layout */}
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 sm:gap-12 mt-16 sm:mt-28">
                <div className="flex flex-col items-center text-center gap-5">
                    <h3 className="text-lg sm:text-xl font-bold bg-gradient-to-br from-fuchsia-500 via-gray-450 to-emerald-600 bg-clip-text text-transparent">
                        Mājaslapu Izveide
                    </h3>
                    <p className="text-sm sm:px-10 lg:px-20">
                        Mēs Piedāvājam dažāda veida mājaslapu veidošanu, tā var būt vienkārša
                        lapa ar informatīvu tekstu, vai mājaslapa, kas sadalīta vairākās sekcijās ar dažnedažādām
                        animācijām un/vai bildēm. Jūs mums pastāstat ko vēlaties, un mēs
                        to izveidojam.
                    </p>
                </div>

                <div className="flex flex-col items-center text-center gap-5">
                    <h3 className="text-lg sm:text-xl font-bold bg-gradient-to-br from-fuchsia-500 via-gray-450 to-emerald-600 bg-clip-text text-transparent">
                        Uzturēšana
                    </h3>
                    <p className="text-sm sm:px-8 lg:px-12">
                        Mēs Piēdāvājam uzturēt jūsu mājaslapu pat pēc tās izveidošanas. Ja esat klients, kas tomēr
                        vēlas, lai jūsu mājaslapa tiek uzturēta, tad mēs to nodrošināsim.
                    </p>
                </div>

                <div className="flex flex-col items-center text-center gap-5">
                    <h3 className="text-lg sm:text-xl font-bold bg-gradient-to-br from-fuchsia-500 via-gray-450 to-emerald-600 bg-clip-text text-transparent">
                        Atsaucīgums
                    </h3>
                    <p className="text-sm sm:px-6 lg:px-8">
                        24h atbilde uz jebkuru jautājumu, kas jums varētu rasties. Mēs vienmēr cenšamies atbildēt pēc
                        iespējas ātrāk un atrisināt problēmu pēc iespējas efektīvāk. Klienta komforts mums ir ļoti
                        svarīgs.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default WhatWeOffer;