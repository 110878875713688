import React from 'react';

const AboutCompany = () => {

    return (
        <div id="AboutCompany" className="min-h-screen flex flex-col items-center justify-center p-8">
            <div className="text-4xl text-white font-bold text-center">
                <h1>
                    Par <span className="text-blueCustom">Mūsu Kompāniju</span>
                </h1>
            </div>
        </div>
        )
    ;
}

export default AboutCompany;