import React, { useState } from 'react';
import { IoMdClose, IoMdMenu } from 'react-icons/io';
import logo from "../img/lucidweb_logo.png"; // Adjust the path to your logo image
import { Link } from 'react-scroll';

const Header = () => {
    const [showContactForm, setShowContactForm] = useState(false);
    const [contactFormAnimation, setContactFormAnimation] = useState('');
    const [isMenuOpen, setIsMenuOpen] = useState(false); // State for hamburger menu

    const toggleContactForm = () => {
        if (!showContactForm) {
            setShowContactForm(true);
            setContactFormAnimation('contact-form-enter');
            setTimeout(() => setContactFormAnimation('contact-form-enter-active'), 10); // Trigger CSS transition
        } else {
            setContactFormAnimation('contact-form-exit');
            setTimeout(() => {
                setShowContactForm(false);
                setContactFormAnimation(''); // Reset animation class
            }, 700); // Match this duration with your CSS transition duration
        }
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen); // Toggle hamburger menu
    };

    return (
        <>
            <header className="fixed top-0 left-0 w-full bg-gray-900 p-2 flex justify-between items-center shadow-md z-50 text-lg">
                <div className="flex items-center">
                    {/* Logo */}
                    <div className="w-12 h-12 rounded-full flex items-center justify-center">
                        <a href="http://lucid-websites.com/"><img src={logo} alt="LucidWeb Logo" className="w-full h-full object-cover rounded-full" /></a>
                    </div>
                    <div className="text-3xl text-white font-bold ml-4">
                        Lucid<span className="text-blue-500">Web</span>
                    </div>
                </div>

                {/* Hamburger Menu Icon for Mobile */}
                <div className="text-white lg:hidden">
                    <button onClick={toggleMenu}>
                        {isMenuOpen ? <IoMdClose size={30} /> : <IoMdMenu size={30} />}
                    </button>
                </div>

                {/* Navigation Links for larger screens */}
                <nav className="hidden lg:flex justify-center text-center text-xl">
                    <Link
                        to="AboutUs"
                        smooth={true}
                        duration={800}
                        className="text-gray-300 hover:text-blue-500 px-4 py-2 cursor-pointer duration-500 ease-in-out"
                    >
                        Par Mums
                    </Link>
                    <Link
                        to="AboutCompany"
                        smooth={true}
                        duration={800}
                        className="text-gray-300 hover:text-blue-500 px-4 py-2 cursor-pointer duration-500 ease-in-out"
                    >
                        Par Kompāniju
                    </Link>
                    <Link
                        to="WhatWeOffer"
                        smooth={true}
                        duration={800}
                        className="text-gray-300 hover:text-blue-500 px-4 py-2 cursor-pointer duration-500 ease-in-out"
                    >
                        Pakalpojumi
                    </Link>
                </nav>

                {/* Contact Button */}
                <div className="hidden lg:block mr-16 text-white text-xl">
                    <button
                        onClick={toggleContactForm}
                        className="text-gray-300 hover:text-blue-500 px-4 py-2 cursor-pointer duration-500 ease-in-out"
                    >
                        Sazināties
                    </button>
                </div>
            </header>

            {/* Mobile Menu */}
            {isMenuOpen && (
                <nav className="fixed top-16 left-0 w-full bg-gray-900 text-center p-4 space-y-4 lg:hidden z-50">
                    <Link
                        to="AboutUs"
                        smooth={true}
                        duration={800}
                        className="block text-gray-300 hover:text-blue-500 py-2 cursor-pointer duration-500 ease-in-out"
                        onClick={toggleMenu}
                    >
                        Par Mums
                    </Link>
                    <Link
                        to="AboutCompany"
                        smooth={true}
                        duration={800}
                        className="block text-gray-300 hover:text-blue-500 py-2 cursor-pointer duration-500 ease-in-out"
                        onClick={toggleMenu}
                    >
                        Par Kompāniju
                    </Link>
                    <Link
                        to="WhatWeOffer"
                        smooth={true}
                        duration={800}
                        className="block text-gray-300 hover:text-blue-500 py-2 cursor-pointer duration-500 ease-in-out"
                        onClick={toggleMenu}
                    >
                        Pakalpojumi
                    </Link>
                    <a className="block text-gray-300 hover:text-blue-500 py-2 cursor-pointer duration-500 ease-in-out">
                        <button
                            onClick={() => {
                                toggleMenu();
                                toggleContactForm();
                            }}
                        >
                            Sazināties
                        </button>
                    </a>
                </nav>
            )}

            {/* Contact Form */}
            {showContactForm && (
                <div
                    className={`fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 ${contactFormAnimation}`}
                >
                    <div
                        className="bg-gray-900 p-4 md:p-8 rounded-lg shadow-lg space-y-4 relative min-w-[24rem] mx-4"
                    >
                        <button
                            className="absolute top-4 right-4 text-white hover:text-gray-500 text-2xl"
                            onClick={toggleContactForm}
                        >
                            <IoMdClose />
                        </button>
                        <div className="text-center">
                            <div className="text-2xl md:text-3xl text-white font-bold">
                                <h1>Sazinies ar mums</h1>
                            </div>
                        </div>
                        <form
                            action="https://getform.io/f/02242e13-485a-4074-8c5a-5981f39bfc7e"
                            method="POST"
                            className="flex flex-col w-full"
                        >
                            <input
                                type="text"
                                name="email"
                                placeholder="E-pasts"
                                className="mb-2 p-2 bg-transparent border-2 border-blue-500 rounded-md text-gray-300 focus:outline-none min-w-[24rem]"
                            />
                            <input
                                type="text"
                                name="Phone Number"
                                placeholder="Telefona Numurs"
                                className="my-2 p-2 bg-transparent border-2 border-blue-500 rounded-md text-gray-300 focus:outline-none min-w-[24rem]"
                            />
                            <input
                                type="text"
                                name="name"
                                placeholder="Vārds, Uzvārds"
                                className="my-2 p-2 bg-transparent border-2 border-blue-500 rounded-md text-gray-300 focus:outline-none min-w-[24rem]"
                            />
                            <textarea
                                name="message"
                                rows="6"
                                placeholder="Ziņojums"
                                className="my-2 p-2 bg-transparent border-2 border-blue-500 rounded-md text-gray-300 focus:outline-none min-w-[24rem]"
                            />
                            <button
                                className="text-gray-200 bg-blue-500 shadow-lg shadow-blue-500/50 px-4 md:px-6 py-3 my-8 mx-auto flex items-center rounded-md hover:scale-110 duration-300"
                            >
                                Send
                            </button>
                        </form>
                    </div>
                </div>
            )}
        </>
    );
};

export default Header;
